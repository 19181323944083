body, #root, html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

body {
  margin: 0;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #006FC6
}

a:hover {
  color: rgba(0, 111, 198, 0.5)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table th.ant-table-cell {
  /* gray-base-8 */
  background-color: #373D3F;
  color: #FFFFFF;
  white-space: nowrap;
  padding: 8px 16px;
}

.ant-table tr.segment-in-review td:first-child {
  padding: 0px;
}

.ant-table-thead tr {
  border-top-radius: 4px;
}

.ant-table table td {
  /* gray-base-8 */
  color: #373d3f;
  font-size: 16px;
}

.ant-table table tr:first-child td {
  border-top: 0;
}

.ant-table table tr:last-child td:last-child {
	border-bottom-right-radius: 4px;
}

.ant-table table tr:last-child td:first-child {
	border-bottom-left-radius: 4px;
}

/* Remove bottom border radius on dashboard tables only */
.dashboard-table .ant-table table tr:last-child td:last-child {
	border-bottom-right-radius: 0;
}

.dashboard-table .ant-table table tr:last-child td:first-child {
	border-bottom-left-radius: 0;
}

.ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 4px;
}

.ant-table table tr td:first-child {
  border-left: 1px solid #DCDCDC;
}

.ant-table table tr td {
  border-bottom: 1px solid #DCDCDC;
  vertical-align: top;
}

.ant-table table tr td:last-child {
  border-right: 1px solid #DCDCDC;
}

.ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 4px;
}

/* On row hover, set bg to white, this is overrided later when a column is sorted */
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr.ant-table-row > td {
	background: #FFFFFF;
}

/* Prevent long text in a td from causing the table to exceed the page width */
.ant-table-tbody > tr.ant-table-row > td {
  word-break: break-word;
}

/* Override the bg color, when a column is sorted */
.ant-table-tbody > tr.ant-table-row > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-column-sort {
	background: #F8F8F8;
}

/* Disable the default hover color on sortable columns in the header*/
.ant-table-thead th.ant-table-column-has-sorters:hover {
  /* gray-base-8 */
  background-color: #373D3F;
}

.ant-table-thead .ant-table-column-sorters {
  padding: 0px;
}

.ant-table-column-sorter {
  color: #fff;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: #a4d649;
}

th {
	text-transform: uppercase;
}

.ant-table-tbody > tr > td {
	padding: 12px 16px;
}

.indexPage input {
	font-size: 16px;
	border-radius: 4px;
	/* silver-base-5 */
  border: 1px solid #c1c1c1;
}

.ant-tabs > .ant-tabs-nav {
  margin-bottom: 0px;
}

.indexPage .ant-input-affix-wrapper {
  flex: 1
}

/* Make the indexPage search input magifying class icon 16px tall */
.indexPage .ant-input-suffix {
	font-size: 16px;
}

.indexPage .ant-table-pagination.ant-pagination {
  margin-top: 42px;
}

.ant-pagination li {
	box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
	box-sizing: content-box;
}

.ant-pagination li.ant-pagination-item-active {
	background-color: #006fc6;
}

.ant-pagination li.ant-pagination-item-active a {
	color: #ffffff;;
}

.ant-pagination li a, .ant-pagination li:hover a {
  /* blue-base-6 */
	color: #006fc6;
}

.ant-pagination li.ant-pagination-item-active:hover a,
.ant-pagination li.ant-pagination-item-active:hover button {
	color: #ffffff;
}

.ant-pagination li.ant-pagination-item-active {
  /* blue-base-6 */
	border-color: #006fc6;
}

.ant-pagination li:hover {
  /* blue-base-6 */
	border-color: #006fc6;
}

.ant-pagination li:hover, .ant-pagination li:hover > :not([disabled]).ant-pagination-item-link {
  /* blue-base-6 */
	border-color: #006fc6;
}

li.ant-pagination-prev,
li.ant-pagination-next {
	color: #373D3F;
}

/* Disable green text highlight on pagination ellipsis when user selects text
as a function of clicking */
a.ant-pagination-item-link {
	user-select: none
}

/* Set the pagination ellipsis text color */
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
	color: #C1C1C1;
}

li.ant-pagination-prev.ant-pagination-disabled,
li.ant-pagination-next.ant-pagination-disabled {
	color: #C1C1C1;
}

/* In pagination, this causes the ellipsis on hover to become a blue '>>' or '<<' icon */
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	color: #373D3F
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next
{
	box-shadow: none;
}

.ant-pagination-jump-next > .ant-pagination-item-link,
.ant-pagination-jump-next > .ant-pagination-item-link > .ant-pagination-item-container,
.ant-pagination-jump-prev > .ant-pagination-item-link,
.ant-pagination-jump-prev > .ant-pagination-item-link > .ant-pagination-item-container
{
	width: 100%;
}

/* Ant does not vertically center the numbers/text in the pagination buttons */
.ant-pagination li {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.ant-pagination li > a {
  font-family: "Roboto", sans-serif;
}

.ant-pagination li:hover > button {
	/* silver-base-5 */
	border-color: #c1c1c1;
}

a.discreet-link:hover {
  /* blue-base-6 */
	color: #006fc6;
}
a.discreet-link {
	color: #373d3f;
}

.detail-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
   height: 6px;
}

.detail-tabs .ant-tabs-tab {
  width: 190px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-family: "Roboto Condensed";
  margin: 0px 8px 0px 0px;
  /* gray-6 */
  color: #6f7c80;
}

.detail-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
  color: #000000;
}

/*----- ant menu -----*/
.global-nav .ant-menu-horizontal {
	border-bottom: none;
	box-shadow: none;
	height: 48px;
	line-height: 48px;
	white-space: nowrap;
}

.global-nav .ant-menu {
	background: #373d3f;
}

.global-nav .ant-menu-horizontal > li.ant-menu-item {
  padding: 0px 10px;
  margin-right: 8px;
  margin-left: 0px;
}

.global-nav .ant-menu-horizontal > .ant-menu-item,
.global-nav .ant-menu-horizontal > .ant-menu-submenu {
	height: 48px;
	top: 0;
}

.global-nav .ant-menu-horizontal > .ant-menu-item:hover,
.global-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.global-nav .ant-menu-horizontal > .ant-menu-item-active,
.global-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.global-nav .ant-menu-horizontal > .ant-menu-item-open,
.global-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.global-nav .ant-menu-horizontal > .ant-menu-item-selected,
.global-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
	border-bottom: 6px solid #6ca00d;
	color: #6ca00d;
}

.global-nav .ant-menu-horizontal > .ant-menu-item a {
	color: #c1c1c1;
	font-size: 18px;
	font-weight: 400;
	text-transform: uppercase;
}

.global-nav .ant-menu-item.ant-menu-item-selected a {
  color: #fff;
}

.global-nav .ant-avatar-string, .global-nav a, .global-nav .ant-btn-link {
	color: #c1c1c1;
}

.global-nav a:hover .ant-avatar-string,
.global-nav a:hover,
.global-nav .ant-btn-link:hover,
.global-nav .ant-menu-horizontal > .ant-menu-item a:hover {
	color: #fff;
}

.global-nav .ant-avatar-string {
  font-size: 14px;
  line-height: 24px;
}
.global-nav .ant-avatar-circle {
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  border: solid 1px #c1c1c1;
}
.global-nav a:hover .ant-avatar-circle {
  border-color: #fff;
}

/* Set the height/vertical centering of antd select */
.ant-select-selection-search,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* See indexSearchHeight constant in src/constants */
  height: 50px;
  /* padding: 8px 14px; */
  /* left: 14px; */
  display: flex;
  align-items: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  padding: 8px 14px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  padding: 8px 16px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 14px;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  font-size: 16px
}

/* The search input of the select does not center the search text, this is a
 * workaround */
.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: -2px;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: text;
}

.ant-select-dropdown {
  border-radius: 4px;
  padding: 0;
}

.ant-select-item {
  align-items: center;
  color: #000;
  font-size: 14px;
  height: 45px;
  padding: 5px 16px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #7f8283;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #7f8283;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-input {
  border-color: #c1c1c1;
  font-size: 16px;
  border-radius: 4px;
}

.ant-input:hover {
  border-color: #7f8283;
}

.ant-input:focus,
.ant-input-focused {
  border-color:#7f8283;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-input-affix-wrapper {
  border-color: #c1c1c1;
}

.ant-input-affix-wrapper:hover {
  border-color: #7f8283;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #7f8283;
  -webkit-box-shadow: none;
  box-shadow: none;
}


.select-create-new.ant-select-item-option-active:not(.ant-select-item-option-disabled), .ant-select-item.select-create-new, .ant-select-item.select-create-new:hover {
  background-color:#373d3f;
  color: #fff;
  font-weight: 700;
}

.select-create-new.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color:#006fc6;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.ant-input.error, .masked-input-container.error {
  border: solid 1px #C82A10;
}

@keyframes fade {
    from { opacity: 0; }
      to { opacity: 1; }
}

/* It's unclear to me why this animation is necessary, playing the animation
 * in reverse has no effect */
@keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

.ant-input-affix-wrapper {
  padding: 8px 14px;
  /* See indexSearchHeight constant in src/constants */
  height: 50px;
}

.ant-input, .masked-input-container {
  /* See indexSearchHeight constant in src/constants */
  height: 48px;
  padding: 8px 14px;
}

.ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    outline: none;

    /* Reset the height if we have the affix wrapper, since the wrapper sets height */
    height: initial;
}

.ant-checkbox-inner {
  border: 2px solid #373d3f;
  border-radius: 3px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #006fc6;
  border-color: #006fc6;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #373d3f;
}

.ant-checkbox + span {
  font-size: 16px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}

.upload-card {
  /* The padding and border depend on one another so that there is no */
  /* vertical/horizontal shift when going from 1px border to 2px border */

  border: 1px dotted #C1C1C1;
  padding: 32px 60px 11px;
}
.upload-card.drag-and-drop:hover, .upload-card.drag-and-drop.drag-over {
  /* The padding and border depend on one another so that there is no */
  /* vertical/horizontal shift when going from 1px border to 2px border */

  border: 2px dotted #6CA00D;
  padding: 31px 59px 10px;
}
.upload-card.drag-and-drop .file-upload-svg-container {
  transition: top 0.3s ease;
  top: 0px;
}
.upload-card.drag-and-drop:hover .file-upload-svg-container {
  top: -16px
}

.home-page .green-tile {
  /* green-base */
  background-color: #6CA00D;

  /* silver2 */
  color: #EAEAEA;
  fill:  #EAEAEA;
}

.home-page .tile {
  /* silver2 */
  color: #EAEAEA;
  fill:  #EAEAEA;
  text-transform: uppercase;
}

.home-page .tile:hover {
  /* whiteBase */
  color: #FFFFFF;
  fill: #FFFFFF;
}

.home-page .green-tile:hover {
  /* green-base accent */
  background-color: #74ab0e;
}

.home-page .blue-tile {
  /* blue-base */
  background-color: #006FC6;
}

.home-page .blue-tile:hover {
  /* blue-base accent */
  background-color: #0076d1;
}

.home-page .top-tile:hover {
  box-shadow: 0px 4px 8px rgba(0,0,0,1);
}

.home-page .upper-left-tile:hover {
  box-shadow: 4px 4px 8px rgba(0,0,0,1);
}

.home-page .upper-right-tile:hover {
  box-shadow: -4px 4px 8px rgba(0,0,0,1);
}

.home-page .bottom-right-tile:hover {
  box-shadow: -4px -4px 8px rgba(0,0,0,1);
}

.home-page .bottom-left-tile:hover {
  box-shadow: 4px -4px 8px rgba(0,0,0,1);
}

.masked-input-container {
  border: 1px solid #c1c1c1;
}

.masked-input-container:not(.error):focus-within {
  border: 1px solid black;
}

/* Dashboard page headers */
.table-header {
  color: #6ca00d;
  display: block;
}

.table-header svg {
  fill: #6ca00d;
  transition: fill 0.3s;
}

.table-header:hover {
  color: #006FC6;
}
.table-header:hover svg {
  fill: #006FC6;
  transition: fill 0.3s;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  /* grayBase8 */
  border-color: #373D3F;
}

.ant-radio .ant-radio-inner {
  /* grayBase8 */
  border-color: #373D3F;
  border-width: 2px;
}

.ant-radio-checked .ant-radio-inner::after {
  /* blueBase */
  background-color:  #006FC6;
  top: 2px;
  left: 2px;
}

